import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'









function Popup() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(58);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [programs, setPrograms] = useState([])
    const navigate = useNavigate()

    const programsCollectionRef = collection(db, "Popup");
    useEffect(() => {

        const getPrograms = async () => {
            const data = await getDocs(programsCollectionRef);
            setPrograms(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getPrograms()
    }, [])
    return (

        <>

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {programs.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralcericultureberhampore) => {
                            return (
                                <div className="col-md-12 mt-3">
                                    <div className='hoverimage1'>
                                        <img className="card-img-top image2" src={centralcericultureberhampore.img} alt={centralcericultureberhampore.title} />
                                        <div className="overlay1"> {centralcericultureberhampore.title}</div>
                                    </div>
                                    <div className="card-body">
                                        <center>
                                        </center>
                                        <div className="d-flex justify-content-between align-items-center">
                                        </div>
                                    </div>


                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Popup;



