import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import Navbar from '../inc/Navbar'

import ScrollToTop from "react-scroll-to-top";
import Julamam from "../images/Jula mam.png"
import "./Home.css";
// import Techmulberry from './Techmulberry';
import News from './News';
import Program from './Program';
import Slider2 from '../inc/Slider2';
import Linkimp from './Linkimp';
import Hostplantimpro from './Hostplantimpro';
import MullberryProtect from './MullberryProtect';
import Admissionpgds from './Admissionpgds';
import Postponement from './Postponement';
import Pgdsmulberry from './Pgdsmulberry';
import EventHeld from './EventHeld';
import Map from './Map';
import DirectorImg from './DirectorImg';
import Popup from './Popup';



function Home() {

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
      const timer = setTimeout(() => {
          setModalOpen(true);
      }, 3000);

      return () => clearTimeout(timer);
  }, []);

  const closeModal = () => setModalOpen(false);

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />




      {/*    
      <div className="album py-2">

<div className="container">
  <h4>Central Silk Board</h4>
  </div>
  </div> */}
      <div className="sericulturalberhampore">
        <div className="album py-0">
          <div className="container">
            <div className="row ">
              <div className="col-md-12" >

                <center>
                  <div className="sericulturalberhampore">
                    <h2 className='mb-1 mt-3'>CENTRAL SERICULTURAL RESEARCH AND TRAINING INSTITUTE</h2>
                  </div>
                  <p className='mb-1'>BERHAMPORE - 742101  (WEST BENGAL)</p>

                  <h6 style={{ color: "red" }}>CENTRAL SILK BOARD</h6>
                  <h6>Ministry of Textiles, Govt. of India</h6>
                </center>

              </div>

              <div className="d-flex justify-content-between align-items-center">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='marqueecolor'>
        <div className="album py-1">

          <div className="container mt-3">
            <marquee><h6 style={{ color: "white" }}>WELCOME TO CENTRAL SERICULTURAL RESEARCH AND TRAINING INSTITUTE | BERHAMPORE - 742101  (WEST BENGAL) | CENTRAL SILK BOARD | Ministry of Textiles, Govt. of India</h6></marquee>

          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="darkBlue" />


      <Slider2 />

      <br />




      
      <div className='App'>

<Modal isOpen={isModalOpen} onRequestClose={closeModal}>
    <div className="album py-2">
        <div className="container">
            <div className="row">
                <br />
            </div>
        </div>
    </div>
    <br />
    <br />
<br/>
<br/>
     <Popup/>
    
    <br />
   



    {/* <div className="album py-2">
        <div className="container">
            <div className="row">

                <Carousel responsive={responsive}>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={muftilogo} alt="blackberrys" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Loiusphilippe} alt="Loiusphilippe" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={blackberrys} alt="muftilogo" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={VanHeusen} alt="blackberrys" />

                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Allensolly} alt="Allensolly" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Parkavenue} alt="Parkavenue" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={spykar} alt="spykar" />

                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={killer} alt="killer" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={turtle} alt="turtle" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={beinghuman} alt="beinghuman" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={mantecarlo} alt="mantecarlo" />
                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={papejeanslondon} alt="papejeanslondon" />
                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={sweetdream} alt="sweetdream" />
                    </div>
                </Carousel>
            </div>
        </div>

    </div> */}
    <div className="d-flex justify-content-end align-items-center mb-3">
        <button className='btn btn-secondary btn-sm mt-2' onClick={closeModal}>Close</button>
    </div>
</Modal>
</div>


      <div className='events'>
        <br />
        <div className="album py-1">
          <div className="container">
            <div className="col-md-1">
              <h3>News</h3>
              <hr />
            </div>

          </div>
        </div>

      </div>
      <div className='color'>
        <div className="album py-1">
          <div className="container">

            <div className="row">
              <div className="col-md-9 mt-3">

                <News />

              </div>
              <div className="col-md-3 mt-3">

<DirectorImg/>
                {/* <div className="card">
                  <div className='hoverimage1'>
                    <img src={Julamam} className='card-img-top image2 mb-2 me-2' alt="director" />
                    <div className="overlay1">Dr. Jula S. Nair <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg></div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title mb-2">Dr. Jula S. Nair</h5>
                    <h4 className="card-title mb-3"> DIRECTOR</h4>
                    <a href='director_speech'>
                      <button className='directors'> DIRECTOR SPEECH</button>
                    </a>
                   
                  </div>

                </div> */}
              </div>

            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <br />
      <Linkimp />
      <br />
      <div className='researchprogram'>
        <br />
        <br />
        <center><h3>Research Programs</h3></center>
        <br />
        <Program />
        <br />
        <br />
      </div>



      {/* 
      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card border-success text-center ">
                <div className="card-header">
                  EVENTS HELD
                </div>
                <div className="card-body">

                 <EventHeld/>

                </div>

              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card border-primary text-center">
                <div className="card-header blink" style={{ color: "maroon" }}>
                  <b>    LATEST NEWS</b>
                </div>
                <div className="card-body">
                <marquee scrollamount="1" direction="up"  >
                  <p style={{ color: "green" }} className="card-title mb-5"><b> <Admissionpgds/></b></p>
                  </marquee>
                  <marquee scrollamount="1" direction="up">
                  <p style={{ color: "green" }} className="card-text mb-5"><b>  <Postponement/></b></p></marquee>
                  <marquee scrollamount="1" direction="up">
                  <p style={{ color: "green" }} className="card-text mb-5"> <b><Pgdsmulberry/> </b></p>
                  </marquee>
                </div>

              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card border-danger text-center">
                <div className="card-header">
                  News Update Text
                </div>
                <div className="card-body">
                  <h5 className="card-title">News will be update soon..........</h5>
                  <h6 className="card-text">Description...</h6>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div> */}








    </div>
  )
}

export default Home